

:root {
  width: 100%;
  height: 100%;
  //background-image: linear-gradient(#108b9a, #2baee2, #055767);
  background-color: black;
	background-size: 1400% 1400%;
	height: 100vh;
  color: white;
  position: relative;
  z-index: 1;
}

.body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.title {
  font-size: 4em;
  padding-bottom: 30px;
  white-space: nowrap;
}

/* CSS Reset */
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
* {
  line-height: calc(1em + 0.5rem);
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}
/* End of CSS Reset */


/* Header */
.App-header {
  display: flex;
  font-size: 1.2em;
  position: sticky;
  top: 4%;
  z-index: 9;
}

.App-header ul {
  display: flex;
  justify-content: center;
  gap: max(2px, 2%);
  margin: auto;
  padding: 10px; 
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 50px;
}

.App-header ul li {
  background-color: rgba(0, 0, 0, 0.07);
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 30px;
  display: inline;
  -webkit-transition: 0.5s ease;
  max-height: 50px;
}
.App-header ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.30);
  text-shadow: 0px -2px 0px rgb(255, 255, 255, 0.3);
}
.App-header ul li:first-child {
  min-width: 50px;
  max-width: 50px;
}
.selected-heading {
  background-color: rgb(0, 0, 0);
}
#scroll-header {
  background-color: rgba(18, 32, 49, 0.401);
  backdrop-filter: blur(10px);
}


/* Home */
.Home {
  padding-top: 20vh;
  text-align: left;
  padding-left: 7%;
}
.dots {
  min-width: 320px;
  padding-left: 0%;
  transition: all 1s ease-in-out;
}
.dot {
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.dot:first-child {
  height: 135px;
  width: 135px;
  z-index: 1;
  opacity: 0.92;
  margin-bottom: 40px;
  animation: MoveUpDown 5s linear infinite;
}

.dot:nth-child(2) {
  height: 190px;
  width: 190px;
  z-index: 2;
  margin-left: -80px;
  animation: MoveUpDown 7s linear infinite;
}
.dot:nth-child(3) {
  height: 160px;
  width: 162px;
  opacity: 0.98;
  z-index: 1;
  margin-left: -80px;
  animation: MoveUpDown 6s linear infinite;
}

.home-intro {
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  margin-top: -165px;
  margin-left: 50px;
  color: black;
}

  @media(min-width: 500px) {
  .dot:first-child {
    height: 190px;
    width: 190px;
    z-index: 1;
    opacity: 0.92;
    margin-bottom: 40px;
    animation: MoveUpDown 5s linear infinite;
  }

  .dot:nth-child(2) {
    height: 300px;
    width: 300px;
    z-index: 2;
    margin-left: -120px;
    animation: MoveUpDown 7s linear infinite;
  }
  .dot:nth-child(3) {
    height: 240px;
    width: 245px;
    opacity: 0.98;
    z-index: 1;
    margin-left: -150px;
    margin-bottom: -20px;
    animation: MoveUpDown 6s linear infinite;
  }

  .home-intro {
    margin-top: -200px;
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.hover:hover {
  cursor: pointer;
}


/* About */

.Home, .About, .Portfolio, .Contact {
  padding-top: 15vh;
  padding-left: 15vw;
  padding-right: 15vw;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.0s; /* don't forget to set a duration! */
}
@media screen and (max-width: 700px) {
  .Home, .About, .Portfolio, .Contact {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .Home {
    padding-left: 1vw;
    padding-right: 0vw;
  }
}

.about-intro {
  padding-bottom: 300px;
}

.about-title{
  display: flex;
}

.experience-section {
  display: flex;
  gap: 30px;
  padding-bottom: 60px;
}
.experience-date {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.661);
}
.experience-description {
  display: block;
  max-width: 500px;
}
.experience-description p {
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.experience-title {
  display: flex;
  gap: 10px;
  padding-bottom: 15px;
  white-space: nowrap;
}
@media screen and (max-width: 800px){
  .experience-section {
    display: block;
  }
  .experience-description {
    min-width: 40vw;
  }
}
.resume {
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1.2em;
}
.resume:hover {
  transition: 0.5s ease-in-out;
  font-size: 1.3em;
}


.down-arrow {
  width: 40px;
  position: relative;
  bottom: 180px;
  margin: auto;
  animation: MoveUpDown 3s linear infinite;
}
#avatar {
  height: 60px;
}

/* Portfolio */
.Portfolio {
  max-width: 100vw;
}
.project-options{ 
  list-style-type: none;
  padding-left: 0;
}
.project-options li { 
  display: inline;
  margin-right: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}
.project-options li:hover {
  cursor: pointer;
}
.unselected {
  background-color: rgba(255, 255, 255, 0.12);
}
.selected {
  background-color: rgba(255, 255, 255, 0.06);
}
.projects-container {
  display: flex;
  gap: 20px;
  padding-top: 40px;
}
.projects-container { 
  cursor: pointer;
}
.projects-left, .projects-right {
  width: 50vw;
  margin-right: 10px;
}
/* Project */
.project {
  width: 100%;
  border-radius: 15px;
  margin-bottom: 50px;
  transition: all 0.7s ease-in-out;
  animation: fadeIn;
  animation-duration: 0.7s;
}
.project img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.project-intro {
  display: none;
  margin-top: -30px;
  padding-left: 10px;
}
.project img:hover {
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.263) 0%,rgb(0, 0, 0) 100%);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.263) 0%,rgb(0, 0, 0) 100%);
}
.project-intro h3{
  font-size: 32px;
  margin-top: -20px;
}
.travel-card .project-intro h3{
  font-size: 60px;
  margin-top: -50px;
}
.project:hover .project-intro {
  display: block;
  float: left;
}

@media screen and (max-width: 1143px) {
  .projects-container {
    display: block;
  }
  .project {
    width: 70vw;
  }
}
@media screen and (max-width: 700px) {
  .project {
    width: 80vw;
  }
}
.hidden {
  display: none;
  visibility: hidden;
}

/* Expanded Project */
.project-expanded, .travel-expanded {
  display: block;
  visibility: visible;
  background-image: linear-gradient(rgb(255, 255, 255) , rgb(216, 216, 216));
  border-radius: 15;
  position: fixed;
  z-index: 10;
  right: 0;
  top: 0;
  bottom: 0;
  width: max(50vw, 500px);
  border-radius: 8px;
  animation: slideInRight;
  animation-duration: 0.5s;
  color: black;
  padding: 40px;
  padding-top: 40px;
  overflow-y: scroll;
}

/* Custom for Travel */
.travel-expanded {
  width: 100vw;
  padding-left: max(50px, 20vw);
  padding-right: max(50px, 20vw);
}

@media screen and (max-width: 500px) {
  .project-expanded, .travel-expanded {
    width: 100vw;
  }
}

.project-title {
  padding-bottom: 20px;
}
.project-description {
  padding-top: 40px;
  padding-bottom: 20px;
}
.project-wrapper img {
  width: 90%;
  border-radius: 15px;
}
.technologies {
  padding-bottom: 20px;
}
.technologies h3 {
  padding-bottom: 10px;
}
.tech-item {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 8px;
  font-weight: bold;
}
.project-hidden {
  visibility: hidden;
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 1; 
  cursor: pointer; 
}
.project-expanded button, .travel-expanded button {
  border-radius: 8px;
  border: 1px solid white;
}
.project-expanded button img, .travel-expanded button img {
  height: 20px;
}
.back-arrow:hover {
  cursor: pointer;
}


/* Contact */
.Contact {
  display: flex;
  padding-bottom: 100px;
}

.contact-form {
  max-width: 40%;
}
.contact-input {
  width: 400px;
  padding: 10px;
  border: 2px solid rgba(17, 7, 78, 0.101);
  background-color: rgba(255, 255, 255, 0.045);
  color:rgba(250, 250, 250, 0.309);
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 1.3em;
}

.Contact button {
  color: black;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight:600;
}
.contact-links {
  text-align: left;
  padding-left: 20px;
}
.contact-links h3 {
  padding-bottom: 10px;
}
.contact-links-container {
  display: flex;
  gap: 5px;
}
.contact-links img{
  border-radius: 8px;
  margin-top: 10px;
  width: 40px;
}


@media(max-width: 768px) {
  .Contact {
    display: inline-block;
  }
  .contact-links {
    padding-left: 0px;
  } 
}


/* TRAVELS */
.trip img, .trip p {
  padding-top: 10px;
}
.trip {
  font-size: 1.5em;
}

.project-header {
  padding-top: 20px;
  border-bottom: 5px dotted rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}
.project-header button {
  margin-bottom: 10px;
}